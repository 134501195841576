import * as React from 'react';
import BEMHelper from 'react-bem-helper';
import './Button.css';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /** Callback function to be called when user clicks on button */
  children: React.ReactNode;
  /** Renders a disabled button and prevents onClick */
  outline?: boolean;
  primary?: boolean;
  block?: boolean;
}

const classes = new BEMHelper('Button');

const Button: React.SFC<ButtonProps> = ({
  children,
  disabled = false,
  onClick,
  outline = false,
  primary = false,
  block = false,
  className,
}) => (
  <button
    {...classes(undefined, { outline, primary, block }, className)}
    disabled={disabled}
    onClick={onClick}
  >
    {children}
  </button>
);

export default Button;
